import { MagicCircle } from '@magic-circle/client';

import World from './world';

const createScene = (
  canvas: HTMLCanvasElement,
  setBarPosition: (pos: 'left' | 'right') => void
): [MagicCircle, World] => {
  let world: World;

  const magic = new MagicCircle()
    .setup((client) => {
      world = new World(client, canvas, setBarPosition);
      world.renderManager.initialize();
      return world.renderer.domElement;
    })
    .loop((delta) => {
      world.tick(delta);
    });

  return [magic, world];
};

export default createScene;
