/* eslint-disable no-underscore-dangle */

import { Texture, Geometry, Vector3, PointsMaterial, Points } from 'three';
import { NumberControl, Layer, BooleanControl } from '@magic-circle/client';

// Creates a circle, which can be used as a canvas texture
function createTexture() {
  // use canvas object for shape
  const _canvas = document.createElement('canvas');
  const _context = _canvas.getContext('2d');

  // square dimensions
  _context.canvas.width = 32;
  _context.canvas.height = 32;

  // draw circle
  _context.fillStyle = 'rgba(255,255,255,1.0)';
  _context.beginPath();
  _context.arc(16, 16, 12, 0, 2 * Math.PI);
  _context.fill();

  // create texture
  const texture = new Texture(_canvas);
  texture.needsUpdate = true;

  return texture;
}

const BUFFER_SIZE = 1000;

class Cloud {
  constructor(world, brain) {
    this.world = world;
    this.brain = brain;

    // create particles
    this.geometry = new Geometry();
    this.lines = [];
    this.timings = [];
    for (let i = 0; i < BUFFER_SIZE; i += 1) {
      this.lines.push(
        brain.lines[Math.floor(Math.random() * brain.lines.length)]
      );
      this.timings.push(Math.random());

      this.geometry.vertices.push(new Vector3());
      this.geometry.colors.push(this.lines[i].color.clone());
    }

    // create point cloud material, use custom texture
    this.material = new PointsMaterial({
      size: 1.15,
      vertexColors: true,
      sizeAttenuation: true,
      map: createTexture(),
    });

    this.material.alphaTest = 0.5;
    this.cloud = new Points(this.geometry, this.material);
    this.cloud.frustumCulled = false;

    // add to 3D space
    brain.scene.add(this.cloud);

    // Create GUI
    new Layer('Particles')
      .addTo(world.gui.layer)
      .add([
        new NumberControl(this.material, 'size').range(0.1, 3),
        new BooleanControl(this.material, 'sizeAttenuation'),
        new BooleanControl(this.material, 'vertexColors'),
      ]);
  }

  render() {
    for (let i = 0; i < BUFFER_SIZE; i += 1) {
      const point = this.lines[i].curve.getPointAt(this.timings[i]);
      this.geometry.vertices[i].copy(point);

      // step
      this.timings[i] += 0.0004;
      this.timings[i] = this.timings[i] >= 1 ? Math.random() : this.timings[i];
    }

    this.geometry.verticesNeedUpdate = true;
    this.geometry.colorsNeedUpdate = true;
  }
}

export default Cloud;
