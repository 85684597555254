/* eslint-disable operator-assignment */
import { Scene, MeshLambertMaterial, Color } from 'three';
import { BooleanControl, ColorControl, Layer } from '@magic-circle/client';

import Line from './line';

import { mapLinear } from '../utils/math';

const BUFFER_SIZE = 20;

class Brain {
  constructor(world, setBarPosition) {
    this.world = world;

    // create scene to rotate
    this.scene = new Scene();
    world.scene.add(this.scene);

    // colors
    this.colors = ['#e73232', '#b61111', '#ff0000', '#000000'];
    this.materials = [];

    // create material per color
    for (let i = 0; i < this.colors.length; i += 1) {
      const mat = new MeshLambertMaterial({
        color: new Color(this.colors[i]),
        fog: true,
      });
      this.materials.push(mat);
    }

    // create line
    this.lines = [];
    for (let i = 0; i < BUFFER_SIZE; i += 1) {
      const line = new Line(this.materials);
      this.lines.push(line);
      this.scene.add(line.mesh);
    }

    this.mouse = 0;
    this.scroll = 0;
    this.rotation = 0.007;

    // listen to mouse changes
    document.addEventListener('mousemove', this.mouseMove.bind(this));
    window.addEventListener('scroll', this.scrollEvent.bind(this));

    // select dom element
    this.setBarPosition = setBarPosition;
    this.bar = 'left';

    // Create GUI
    const materialsLayer = new Layer('Lines').addTo(world.gui.layer);

    this.materials.forEach((mat, i) => {
      const materialLayer = new Layer(`Material ${i + 1}`).addTo(
        materialsLayer
      );

      materialLayer.add([
        new ColorControl(mat, 'color').label('Color').range(1),
        new BooleanControl(mat, 'fog').label('Fog'),
      ]);
    });
  }

  mouseMove(event) {
    this.mouse = event.clientX / window.innerWidth;
  }

  scrollEvent() {
    this.scroll = window.scrollY;
  }

  render() {
    // rotate
    if (this.mouse < 0.5) this.scene.rotation.y += this.rotation;
    if (this.mouse >= 0.5) this.scene.rotation.y -= this.rotation;

    // update FX
    const { world } = this;
    const blur = 20;

    if (this.mouse < 0.5) {
      // blurry
      if (!this.world.bypass) {
        world.trace.uniforms.pathLength.value = 0.02;
        world.tiltHori.uniforms.h.value = blur / this.world.width;
        world.tiltVert.uniforms.v.value = blur / this.world.width;
        world.rgbShift.uniforms.amount.value = 0.0015;
        world.tracers.material.size = 1;
        world.pointLight.intensity = 20;
      }

      if (this.bar === 'left') {
        this.setBarPosition('right');
        this.bar = 'right';
      }
    } else {
      // sharp
      if (!this.world.bypass) {
        world.trace.uniforms.pathLength.value = 0.2;
        world.tiltHori.uniforms.h.value = (blur * 0.2) / this.world.width;
        world.tiltVert.uniforms.v.value = (blur * 0.2) / this.world.width;
        world.rgbShift.uniforms.amount.value = 0.0007;
        world.tracers.material.size = 0.7;
        world.pointLight.intensity = 5;
      }

      if (this.bar === 'right') {
        this.setBarPosition('left');
        this.bar = 'left';
      }
    }

    this.target = mapLinear(this.scroll, 0, 800 + window.innerHeight, 0, 100);
    const delta = this.target - this.scene.position.y;
    this.scene.position.y = this.scene.position.y + delta * 0.2;
  }
}

export default Brain;
