import { CatmullRomCurve3, TubeGeometry, Vector3, Mesh } from 'three';

import noise from './lib/noise';

class Line {
  constructor(materials) {
    const points = 100;
    const list = [];
    const offset = 10;
    const spread = 10;

    list.push(this.getRandomPoint(spread));

    for (let i = 0; i < points; i += 1) {
      const previous = list[i];
      const next = this.getPerlinPoint(previous, offset);
      list.push(next);
    }

    const width = 0.05;
    const curve = new CatmullRomCurve3(list);
    const tube = new TubeGeometry(curve, 1000, width, 6, false);
    const material = materials[Math.floor(Math.random() * materials.length)];

    this.color = material.color;
    this.mesh = new Mesh(tube, material);
    this.curve = curve;
  }

  getPerlinPoint(vec3, offset) {
    const n1 = noise.simplex3(vec3.x + 50, vec3.y + 50, vec3.z + 50) * offset;
    const n2 = noise.simplex3(vec3.y + 50, vec3.x + 50, vec3.z + 50) * offset;
    const n3 = noise.simplex3(vec3.z + 50, vec3.x + 50, vec3.y + 50) * offset;
    return vec3.clone().add(new Vector3(n1, n2, n3));
  }

  getRandomPoint(spread) {
    const spreadHalf = spread / 2;
    return new Vector3(
      Math.random() * spread - spreadHalf,
      Math.random() * spread - spreadHalf,
      Math.random() * spreadHalf
    );
  }
}

export default Line;
