import {
  WebGLRenderer,
  PerspectiveCamera,
  Vector3,
  Scene,
  HemisphereLight,
  PointLight,
} from 'three';

import {
  BooleanControl,
  Folder,
  Layer,
  NumberControl,
} from '@magic-circle/client';

import RenderPipeline from './lib/render-pipeline';
import RenderStep from './lib/render-step';
import ShaderStep from './lib/shader-step';

import simpleVertex from './shaders/simple.vert.glsl';
import tiltVertiFragment from './shaders/tilt-v.frag.glsl';
import tiltHoriFragment from './shaders/tilt-h.frag.glsl';
import rgbFragment from './shaders/rgb-shift.frag.glsl';
import copyFragment from './shaders/copy.frag.glsl';
import traceFragment from './shaders/trace.frag.glsl';

import Brain from './brain';
import Cloud from './cloud';

class World {
  constructor(gui, canvas, setBarPosition) {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.gui = gui;
    this.bypass = false;

    this.renderer = new WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setSize(this.width, this.height);
    // this.renderer.shadowMap.enabled = true;
    // this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    // add DOM element
    canvas.appendChild(this.renderer.domElement);

    // setup scene & camera
    this.scene = new Scene();
    const cameraRatio = this.width / this.height;
    this.camera = new PerspectiveCamera(70, cameraRatio, 1, 100);
    this.camera.position.set(0, 0, -35);
    this.camera.lookAt(new Vector3());

    // const cameraControls = controls.layer('Camera');

    // cameraControls.folder('position', [
    //   new NumberControl(this.camera.position, 'x').range(-40, 40),
    //   new NumberControl(this.camera.position, 'y').range(-40, 40),
    //   new NumberControl(this.camera.position, 'z').range(-100, 100),
    // ]);

    // create needed elements
    this.renderManager = new RenderPipeline(this.renderer);
    this.tiltVert = new ShaderStep(this.width, this.height);
    this.tiltHori = new ShaderStep(this.width, this.height);
    this.save = new ShaderStep(this.width, this.height);
    this.trace = new ShaderStep(this.width, this.height);
    this.rgbShift = new ShaderStep(this.width, this.height);
    this.renderPass = new RenderStep(
      this.width,
      this.height,
      this.scene,
      this.camera
    );

    this.trace
      .setting('pathLength', 'f', 0.01)
      .setting('texRenderPass', 't', this.renderPass.link())
      .shader('vertex', simpleVertex)
      .shader('fragment', traceFragment);

    const blur = {
      pos: 0.5,
      amount: 4,
      spread: 1,
    };

    this.tiltVert
      .setting('v', 'f', (1 / this.height) * blur.amount)
      .setting('r', 'f', blur.pos)
      .setting('spread', 'f', blur.spread)
      .shader('vertex', simpleVertex)
      .shader('fragment', tiltVertiFragment)
      .pipe();

    this.tiltHori
      .setting('h', 'f', (1 / this.width) * blur.amount)
      .setting('r', 'f', blur.pos)
      .setting('spread', 'f', blur.spread)
      .shader('vertex', simpleVertex)
      .shader('fragment', tiltHoriFragment)
      .pipe();

    this.rgbShift
      .setting('amount', 'f', 0.0015)
      .setting('angle', 'f', 0.5)
      .shader('vertex', simpleVertex)
      .shader('fragment', rgbFragment)
      .pipe();

    // copy
    this.save
      .shader('vertex', simpleVertex)
      .shader('fragment', copyFragment)
      .pipe()
      .renderToScreen(true)
      .needSwap(false);

    // create light
    this.hemisphere = new HemisphereLight(0xffffff, 0xdddddd, 0.8);
    this.scene.add(this.hemisphere);
    this.pointLight = new PointLight(0x4487c4, 10, 50);
    this.scene.add(this.pointLight);

    // create 'brain element'
    this.brain = new Brain(this, setBarPosition);
    this.tracers = new Cloud(this, this.brain);

    // make pipeline
    this.renderManager
      .pipe('brain', (d) => this.brain.render(d))
      .pipe('tracers', (d) => this.tracers.render(d))
      .pipe('render', this.renderPass)
      .pipe('trace', this.trace)
      .pipe('tilt-v', this.tiltVert)
      .pipe('tilt-h', this.tiltHori)
      .pipe('rgbShift', this.rgbShift)
      .pipe('save', this.save);
    // .start();

    // Create GUI
    const layerFX = new Layer('FX').addTo(gui.layer);

    new Folder('Mouse interaction', [
      new BooleanControl(this, 'bypass').label('Bypass'),
    ]).addTo(layerFX);

    new Folder('Trace', [
      new NumberControl(this.trace.uniforms.pathLength, 'value')
        .label('Path length')
        .range(0, 1)
        .stepSize(0.001)
        .watch(),
    ]).addTo(layerFX);

    new Folder('RGB Shift', [
      new NumberControl(this.rgbShift.uniforms.amount, 'value')
        .label('Amount')
        .range(0, 0.1)
        .stepSize(0.001)
        .watch(),
      new NumberControl(this.rgbShift.uniforms.angle, 'value')
        .label('Angle')
        .range(-Math.PI, Math.PI)
        .stepSize(0.01)
        .watch(),
    ]).addTo(layerFX);

    new Folder('Tilt Shift (H)', [
      new NumberControl(this.tiltHori.uniforms.r, 'value')
        .label('Position')
        .range(0, 1)
        .stepSize(0.01)
        .watch(),
      new NumberControl(this.tiltHori.uniforms.h, 'value')
        .label('Amount')
        .range(0, 0.1)
        .stepSize(0.001)
        .watch(),
      new NumberControl(this.tiltHori.uniforms.spread, 'value')
        .label('Spread')
        .range(0, 2)
        .stepSize(0.1)
        .watch(),
    ]).addTo(layerFX);

    new Folder('Tilt Shift (V)', [
      new NumberControl(this.tiltVert.uniforms.r, 'value')
        .label('Position')
        .range(0, 1)
        .stepSize(0.01)
        .watch(),
      new NumberControl(this.tiltVert.uniforms.v, 'value')
        .label('Amount')
        .range(0, 0.1)
        .stepSize(0.001)
        .watch(),
      new NumberControl(this.tiltVert.uniforms.spread, 'value')
        .label('Spread')
        .range(0, 2)
        .stepSize(0.1)
        .watch(),
    ]).addTo(layerFX);
  }

  tick(delta) {
    this.renderManager.step(delta);
  }

  resize() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.renderer.setSize(this.width, this.height);

    // update camera
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
  }
}

export default World;
